import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidenavComponent } from './sidenav/sidenav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientModule} from '@angular/common/http';
import {AuthenticationModule} from './authentication/authentication.module';
import {BlockUIModule} from 'ng-block-ui';
import { PrintErrorComponent } from './shared/print-error/print-error.component';

@NgModule({
    declarations: [
        AppComponent,
        SidenavComponent,
    ],
    imports: [
        BrowserModule,
        BlockUIModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatToolbarModule,
        MatDividerModule,
        MatListModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        MatExpansionModule,
        FlexLayoutModule,
        HttpClientModule
    ],
    providers: [AuthenticationModule],
    bootstrap: [AppComponent]
})
export class AppModule { }
