import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class BpHttpService {
  protected baseUrl = environment.apiUrl;


  constructor(protected http: HttpClient) { }

  delete(httpOptions: object, url?: string) {
    return this.http
      .delete(`${this.baseUrl}/${url}`, httpOptions)
      .pipe(catchError(this.onRequestError('DELETE')));
  }

  get(httpOptions: object, uri?: string): Observable<any> {
    const url = uri ? `${this.baseUrl}/${uri}` : this.baseUrl;
    return this.http.get(url, httpOptions).pipe(catchError(this.onRequestError('GET')));
  }

  post(httpOptions: object, body?: object, url?: string): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/${url}`, body, httpOptions)
      .pipe(catchError(this.onRequestError<any>('POST')));
  }

  put(httpOptions: object, body?: object, url?: string): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/${url}`, body, httpOptions)
      .pipe(catchError(this.onRequestError<any>('PUT')));
  }

  patch(httpOptions: object, body?: object, url?: string): Observable<any> {
    return this.http
      .patch(`${this.baseUrl}/${url}`, body, httpOptions)
      .pipe(catchError(this.onRequestError<any>('PATCH')));
  }

  private onRequestError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.status <= 404) {
        Swal.fire( {
          icon: 'error',
          text: error.error.message
        }).then(r => window.location.replace(environment.appUrl) );
      } else if (error.status >= 405) {
        Swal.fire( {
          icon: 'error',
          text: 'Ha ocurrido un error y tu solicitud no puede ser procesada, por favor contácta al departamento de TI para solucionarlo'
        });
      }
      return of(result as T);
    };
  }
}
