import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import Storage from '@aws-amplify/storage';
import Auth from '@aws-amplify/auth';


Auth.configure({
  Auth: {
    identityPoolId: environment.IDENTITY_POLL_ID, // REQUIRED - Amazon Cognito Identity Pool ID
    region: environment.REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: environment.USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: environment.USER_POOL_WEB_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID
  },
});

Storage.configure({
    AWSS3: {
      bucket: environment.BUCKET, // REQUIRED -  Amazon S3 bucket
      region: environment.REGION_AWS, // OPTIONAL -  Amazon service region
    }
});


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Huey';
}
