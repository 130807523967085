// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appUrl: 'https://huey.billpocket.com',
  iauthUrl: 'https://iauth.billpocket.com',
  apiUrl: 'https://operaciones.billpocket.com/api',
  apiDistribuidores: 'https://osprey.billpocket.com/api',
  apiTarjetaRevision: 'https://minuteman.billpocket.com/api',
  apiUsu: 'https://enterprise.billpocket.com/detalle/',
  apiFormaPago: 'https://adelaida.billpocket.com/api/v1/bp/usuario',
  apiEnvio: 'https://nimitz.billpocket.com/envios/',

  IDENTITY_POLL_ID: 'us-east-1:837cc09b-026d-41b9-a6ae-bc088f82fa43',
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_C7Shck2DH',
  USER_POOL_WEB_CLIENT_ID: '52ufhnkh3rgfdn3e3rs24c3nbt',
  BUCKET: 'adelaida-batch-block-users-prod',
  BUCKET_FILE: 'huey-transactionality-reports-prod',
  REGION_AWS: 'us-east-1',
  urlS3: 'https://billpocket-solicitudes-tarjeta-produccion.s3.us-east-2.amazonaws.com',
  urlCobros: 'https://moservice.billpocket.com',
  urlConciliacion: 'https://huey-operations-files.s3.us-east-2.amazonaws.com',
  conciliacionFolders: 'prod/conciliacion_fines_semana',
  estadoDeCuenta: 'https://dashboard.billpocket.com',
  password: '3st4d0Cu3nT@BP',
  EXPIRE_URL: 604800,
  tasa_default_credito: 2.5,
  tasa_default_debito: 2.5,
  tasa_default_amex: 3.2,
  tasa_default_internacional: 2.7,
  tasa_default_teclado_abierto: 2.7,
  BP_PREFIX: "BPK",
  KUSHKI_INTEGRATION: 'KUSHKI_INTEGRATION'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
